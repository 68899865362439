import { Component, Input } from '@angular/core';
import { NAVBARITEMS } from '../../shared/data/navbar-items';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'opm-active-alerts-info',
  templateUrl: './active-alerts-info.component.html',
  styleUrls: ['./active-alerts-info.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    GoogleAnalytics4DirectiveModule,
  ],
})
export class ActiveAlertsInfoComponent {
  @Input() count: number;
  @Input() isInDiagnosticMode: boolean;
  alertsLink = NAVBARITEMS.find((x) => x?.isAlerts)?.routerLink;
}
