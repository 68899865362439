import { Pipe, PipeTransform } from '@angular/core';
import {
  AlertDetailResponse,
  AlertType,
} from '@connected/data-access/fleet-service';
import { CodeResponse } from '@paldesk/shared-lib/data-access/palcode-service-generated';

@Pipe({
  name: 'alert2CodeType',
  standalone: false,
})

// tslint:disable: no-non-null-assertion
export class MapAlertCodePipe implements PipeTransform {
  transform(code: CodeResponse | null): AlertDetailResponse | null {
    if (code) {
      let alertType;
      switch (code.error_type) {
        case '0':
        case 'OS':
        case AlertType.Information:
          alertType = AlertType.Information;
          break;
        case '1':
        case 'WA':
        case AlertType.Warning:
          alertType = AlertType.Warning;
          break;
        case '2':
        case 'SE':
        case AlertType.Error:
          alertType = AlertType.Error;
          break;
        default:
          alertType = AlertType.Error;
          break;
      }
      return {
        code: code.code_number,
        description_long: code.description_long
          ? code.description_long
          : code.description_short,
        cause: code.cause,
        solution: code.solution,
        is_private: !code.is_public,
        alert_type: alertType,
      };
    } else {
      return null;
    }
  }
}
