import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'opm-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatDialogModule,
    DsSpacingModule,
    MatButtonModule,
  ],
})
export class InstallPromptComponent {}
