import {} from '@angular/google-maps';
import { dsConfig } from '@design-system/cdk/config';

export const dsMapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral[100],
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral[50],
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: dsConfig.colors.neutral.contrast[500],
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: dsConfig.colors.neutral.contrast[500],
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral.contrast[500],
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral.contrast[500],
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral[100],
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral[100],
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: dsConfig.colors.neutral.contrast[500],
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: dsConfig.colors.neutral[900],
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: dsConfig.colors.neutral[50],
      },
      {
        lightness: 50,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: dsConfig.colors.neutral[100],
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: dsConfig.colors.neutral[100],
      },
      {
        weight: 1.2,
      },
    ],
  },
];
